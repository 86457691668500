<template>
    <div class="row">
        <div class="col-md-12">
            <div class="mb-3">
                <span class="h4 titulosg">Publicaciones</span><br>
                Registros Econtrados <span class="badge bg-primary"> {{publicaciones.length}}  </span>
            </div>
            <div>
                <input type="text" class="form-control" placeholder="Buscar publicación">
                <button class="btn btn-primary btn-sm" type="button"><i class="fas fa-search"></i> Buscar</button>
            </div>

                <div class="bg-white mt-3 card">
                    <table class="table mb-0 table-hover">
                        <thead>
                            <tr>
                                <td></td>
                                <td>Descripción</td>
                                <td>Fecha de Publicación</td>
                                <td>Estado</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in publicaciones" :key="item.idcontenido" @click="verpublicacion(item.idcontenido)">  
                                <td style="width: 5%;">  </td>                          
                                <td style="width: 40%;"> 
                                  {{item.titulo}}   
                                </td>
                                <td> {{item.fechainicio}} - {{item.fechafin}} </td>
                                <td>
                                    <span class="badge bg-success" v-if="item.estadocontenido==1">Activo</span>
                                    <span class="badge bg-secondary" v-if="item.estadocontenido==2">Inactivo</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'

import { useRouter, useRoute } from 'vue-router'


export default {
    setup(props) {

        const router = useRouter()
        const route = useRoute()

        const publicaciones = ref({})
        const paginas = ref({})
        
        const ConsultarPublicaciones = () => {
            axios.get('/api/publicaciones').then( response => {
                publicaciones.value = response.data.data
            })
        }        

        const verpublicacion = (slug) => {
            router.push({ name: 'infopublicacion', params: { id: slug } })
        }

        onMounted(() => {
            ConsultarPublicaciones()
        })

        return {
            publicaciones,

            //funciones
            verpublicacion

        }
    }
}
</script>

<style>

</style>
